import {Fragment} from "preact";
import Footer from "../../Footer";
import Page from "../../Page";
import HorizontalMenu from "../../HorizontalMenu";
import style from "./style.css"
import {Text} from "preact-i18n";
import PlansSlideshow from "../../PlansSlideshow";
import Content from "../../Content";
import ServerletPlans from "../../ServerletPlans";
import OtherServices from "../../OtherServices";

const Pricing = () => (
    <Fragment>
        <Page withLine={false} withShadow={true} className={style.pricing}>
            <HorizontalMenu>
                <a href="#serverlets"><Text id="serverlets">Serverlets</Text></a>
                <a href="#other"><Text id="other">Others</Text></a>
            </HorizontalMenu>
            <Content>
                <div id="serverlets" className={style.anchor}/>
                <div className={style.section}>
                    <p><Text id="pricing-serverlets-subtitle">All plans of</Text></p>
                    <h1><Text id="pricing-serverlets-title">Serverlet</Text></h1>
                    <ServerletPlans/>
                </div>

                <div id="other" className={style.anchor}/>
                <div id="other" className={style.section}>
                    <h1><Text id="pricing-other-title">Other</Text></h1>
                    <p><Text id="pricing-other-subtitle">fees</Text></p>
                    <OtherServices/>
                </div>

            </Content>
        </Page>
        <Footer/>
    </Fragment>
);

export default Pricing;
