import { Link } from 'preact-router/match';
import style from './style.css';

const Boxes = (props) => (
    <div className={style.boxes}>
        {props.children}
    </div>
);

export default Boxes;
