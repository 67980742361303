import { Link } from 'preact-router/match';
import style from './style.css';

const Box = (props) => (
    <div className={style.box}>
        {props.children}
    </div>
);

export default Box;
