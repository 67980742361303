import { Link } from 'preact-router/match';
import style from './style.css';
import LoginPanel from "../LoginPanel";
import {useEffect, useRef, useState} from "preact/hooks";
import {createRef} from "preact";
import {Text} from "preact-i18n";
import {getCurrentUrl, route} from "preact-router";


const Header = (props) => {
	const headerOffset = 15;
	const headerRef = useRef();
	const [ sticky, setSticky ] = useState(false);
	const newLang = props.lang === "en" ? "cs" : "en";
	const newLangUrl = getCurrentUrl().replace(props.langUrl, newLang === "en" ? "" : "/cs");

	const onScroll = () => {
		if (window.scrollY > headerOffset) {
			headerRef.current.classList.add(style.headerShadow);
			return;
		}
		headerRef.current.classList.remove(style.headerShadow);
	}

	useEffect(() => {
		document.addEventListener('scroll', onScroll);
		return () => {
			document.removeEventListener('scroll', onScroll)
		}
	}, []);

	const changeLang = () => {
		localStorage.setItem('lang', newLang);
	};

	return (
		<header className={style.header} ref={headerRef}>
			<div className={style.headerInner}>
				<div className={style.left}>
					<div className={style.logo}>
						<img alt="futrou logo" src="/assets/img/futrou.svg" width={100}/>
					</div>
				</div>
				<div className={`${style.center} ${style.menu}`}>
					<Link activeClassName={style.active}  href={`${props.langUrl}/`}><Text id="home">Home</Text></Link>
					<Link activeClassName={style.active} href={`${props.langUrl}/products`}>
						<Text id="products">Products</Text>
					</Link>
					<Link activeClassName={style.active} href={`${props.langUrl}/pricing`}><Text id="pricing">Pricing</Text></Link>
					<Link activeClassName={style.active} href={`${props.langUrl}/about-us`}><Text id="about-us">About us</Text></Link>
				</div>
				<div className={style.right}>
					<LoginPanel/>
				</div>
				<div className={style.headerLanguage}>
					<Link href={ newLangUrl } className={style.headerLanguageButton} onClick={changeLang}>
						<span>{ props.lang }</span>
						<span>{ newLang }</span>
					</Link>
				</div>
				<div className={style.headerLanguageLabel}>FUTROU HOSTING</div>
			</div>
		</header>
	)
};

export default Header;
