import { Link } from 'preact-router/match';
import style from './style.css';
import {useCallback, useEffect, useRef, useState} from "preact/hooks";
import {createRef} from "preact";
import {Text} from "preact-i18n";

const LoginPanel = (callback, inputs) => {
    const panelMenuRef = useRef();
    const [ name, setName ] = useState("");
    const [ showMenu, setShowMenu ] = useState(false);

    const handleUserAvatarClick = () => {
        setShowMenu(!showMenu);
    };

    const handleClickOutside = useCallback((e) => {
        if (panelMenuRef.current && !panelMenuRef.current.contains(e.target) && showMenu) {
            setShowMenu(false)
        }
    });

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [handleClickOutside])


    const [ loggedIn, setLoggedIn ] = useState(false);
    useEffect(() => {
        fetch('https://adm_api.futrou.com/user/enter', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
            .then((response) => response.json())
            .then((data) => {
                if(data.success && data.authenticated){
                    setLoggedIn(true);
                    if(data.user?.contact){
                        let name = data.user.contact.personal ? `${data.user.contact.firstname} ${data.user.contact.lastname}` : data.user.contact.company;
                        setName(name);
                    }
                }
            });
    }, [])

    return (
        <div className={`${style.loginPanel} ${ loggedIn ? style.loggedPanel : ""}`}>
            <div className={style.buttons}>
                <a href="https://app.futrou.com/signup" className="left-button"><Text id="signup">Sign up</Text></a>
                <a href="https://app.futrou.com/" className="right-button"><Text id="login">Log in</Text></a>
            </div>
            {
                loggedIn &&
                <div>
                    <div className={style.user} onClick={handleUserAvatarClick}>
                        <div className={style.avatar}>{ name.length > 0 ? name[0] : "" }</div>
                        <span>{ name }</span>
                    </div>
                    <div className={`${style.panelMenu} ${showMenu ? style.show : style.hide }`} ref={panelMenuRef}>
                        <ul>
                            <li>
                                <a className={style.administration} href="https://app.futrou.com"><Text id="control-panel">Control panel</Text></a>
                            </li>
                            <li>
                                <a className={style.settings} href="https://app.futrou.com/settings/email"><Text id="settings">Settings</Text></a>
                            </li>
                            <li>
                                <a className={style.logout} href="https://app.futrou.com/logout"><Text id="logout">Log out</Text></a>
                            </li>
                        </ul>
                    </div>
                </div>
            }
        </div>
    )
};

export default LoginPanel;
