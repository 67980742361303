import { Link } from 'preact-router/match';
import style from './style.css';

const RouterContainer = (props) => (
    <div className={style.routerContainer}>
        {props.children}
    </div>
);

export default RouterContainer;
