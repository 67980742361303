import {IntlProvider} from "preact-i18n";
import config from '../../config';
import {useEffect, useState} from "preact/hooks";
import Error404 from "../404";
import {getCurrentUrl, route} from "preact-router";

const getNavigatorLanguage = () => (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
const getPreferedLang = () => {
    const lang = localStorage.getItem('lang');
    return lang ?? getNavigatorLanguage();
}

const LocaleProvider = (props) => {
    const defaultLang = config["langs-default"];
    const supportedLangs = config.langs;

    const getLang = () => {
        return props.lang.length > 0 && supportedLangs.includes(props.lang) ? props.lang : defaultLang;
    };

    const getLangUrl = (lang) => {
        return lang === defaultLang ? "" : `/${lang}`
    };

    const getLocale = (lang) => {
        return require(`../../l10n/${lang}.json`);
    }

    const [ lang, setLang ] = useState(getLang());
    const [ langUrl, setLangUrl ] = useState(getLangUrl(lang));
    const [ locale, setLocale ] = useState(getLocale(lang));

    useEffect(() => {
        let lang = getLang();
        setLang(lang);
        setLangUrl(getLangUrl(lang));
        setLocale(getLocale(lang));
    }, [props.lang]);

    useEffect(() => {
        let preferedLang = getPreferedLang().split("-")[0];
        console.log(preferedLang);
        if(preferedLang !== lang && supportedLangs.includes(preferedLang)){
            let url = getCurrentUrl().replace(langUrl, "");
            //route(`${getLangUrl(preferedLang)}${url}`);
        }
    }, []);

    return (
        <IntlProvider definition={locale}>
            {props.render({
                lang,
                langUrl
            })}
        </IntlProvider>
    )
};

export default LocaleProvider;
