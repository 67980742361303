import style from './style.css';
import Content from "../Content";

const Footer = () => {
    const sinceYear = 2020;
    const currentYear = new Date().getFullYear();
    return (
        <footer>
            <div className={style.top}>
                <Content noMinHeight={true}>
                    <div className={style.box}>
                        <h2>Důležité odkazy</h2>
                        <a href="">Často kladené dotazy</a>
                        <a href="">Slovník pojmů</a>
                        <a href="">Pro média</a>
                    </div>
                    <div className={style.box}>
                        <h2>Další důležité odkazy</h2>
                        <a href="">Všeobecné obchodní podmínky</a>
                        <a href="">Ochrana osobních údajů</a>
                        <a href="">Soubory cookies</a>
                    </div>
                </Content>
            </div>
            <div className={style.bottom}>
                <p>Made with love in Czechia</p>
                <span>© {sinceYear}-{currentYear}</span>
            </div>
        </footer>
    )
};

export default Footer;
