import { Link } from 'preact-router/match';
import PlansSlideshow from "../PlansSlideshow";
import plansStyle from '../PlansSlideshow/style.css'
import style from './style.css'
import {Text, translate, useText, withText} from "preact-i18n";
import {useState} from "preact/hooks";
import TextToggle from "../TextToggle";

const ServerletPlans = (props) => {
    const [ billingPeriod, setBillingPeriod ] = useState('m');

    const plans = [
        {
            title : "XXS",
            monthlyPrice : 15,
            hourlyPrice : 0.021,
            currency : (<Text id="czk">CZK</Text>),
            description : (<Text id="serverlet-plan-xxs-description">For testing and small static sites</Text>),
            cpu : (<>CPU <span>0.125vCPU</span></>),
            ram : (<>RAM <span>128MB</span></>),
            disk : (<>DISK <span>1GB</span></>),
            maxDomains : (<><Text id="plans-max-domains">MAX domains</Text> <span>100</span></>),
            maxSshAccounts : (<><Text id="plans-max-ssh-accounts">MAX SSH accounts</Text> <span>100</span></>),
        },
        {
            title : "XS",
            monthlyPrice : 55,
            hourlyPrice : 0.077,
            currency : (<Text id="czk">CZK</Text>),
            description : (<Text id="serverlet-plan-xs-description">For small dynamic sites</Text>),
            cpu : (<>CPU <span>0.5vCPU</span></>),
            ram : (<>RAM <span>512MB</span></>),
            disk : (<>DISK <span>5GB</span></>),
            maxDomains : (<><Text id="plans-max-domains">MAX domains</Text> <span>100</span></>),
            maxSshAccounts : (<><Text id="plans-max-ssh-accounts">MAX SSH accounts</Text> <span>100</span></>),
            label : "popular",
            labelTitle : (<Text id="plans-popular">Popular</Text>)
        },
        {
            title : "S",
            monthlyPrice : 110,
            hourlyPrice : 0.153,
            currency : (<Text id="czk">CZK</Text>),
            description : (<Text id="serverlet-plan-s-description">For bigger dynamic sites</Text>),
            cpu : (<>CPU <span>1vCPU</span></>),
            ram : (<>RAM <span>1024MB</span></>),
            disk : (<>DISK <span>10GB</span></>),
            maxDomains : (<><Text id="plans-max-domains">MAX domains</Text> <span>100</span></>),
            maxSshAccounts : (<><Text id="plans-max-ssh-accounts">MAX SSH accounts</Text> <span>100</span></>),
        },
        {
            title : "M",
            monthlyPrice : 220,
            hourlyPrice : 0.306,
            currency : (<Text id="czk">CZK</Text>),
            description : (<Text id="serverlet-plan-m-description">For more dynamic sites</Text>),
            cpu : (<>CPU <span>2vCPU</span></>),
            ram : (<>RAM <span>2560MB</span></>),
            disk : (<>DISK <span>25GB</span></>),
            maxDomains : (<><Text id="plans-max-domains">MAX domains</Text> <span>100</span></>),
            maxSshAccounts : (<><Text id="plans-max-ssh-accounts">MAX SSH accounts</Text> <span>100</span></>),
        },
        {
            title : "L",
            monthlyPrice : 330,
            hourlyPrice : 0.459,
            currency : (<Text id="czk">CZK</Text>),
            description : (<Text id="serverlet-plan-l-description">For bigger dynamic sites</Text>),
            cpu : (<>CPU <span>3vCPU</span></>),
            ram : (<>RAM <span>3072MB</span></>),
            disk : (<>DISK <span>40GB</span></>),
            maxDomains : (<><Text id="plans-max-domains">MAX domains</Text> <span>100</span></>),
            maxSshAccounts : (<><Text id="plans-max-ssh-accounts">MAX SSH accounts</Text> <span>100</span></>),
        },
        {
            title : "XL",
            monthlyPrice : 440,
            hourlyPrice : 0.612,
            currency : (<Text id="czk">CZK</Text>),
            description : (<Text id="serverlet-plan-xl-description">For bigger dynamic sites</Text>),
            cpu : (<>CPU <span>3vCPU</span></>),
            ram : (<>RAM <span>4096MB</span></>),
            disk : (<>DISK <span>60GB</span></>),
            maxDomains : (<><Text id="plans-max-domains">MAX domains</Text> <span>100</span></>),
            maxSshAccounts : (<><Text id="plans-max-ssh-accounts">MAX SSH accounts</Text> <span>100</span></>),
        },
        {
            title : "XXL",
            monthlyPrice : 550,
            hourlyPrice : 0.764,
            currency : (<Text id="czk">CZK</Text>),
            description : (<Text id="serverlet-plan-xxl-description">For bigger dynamic sites</Text>),
            cpu : (<>CPU <span>4vCPU</span></>),
            ram : (<>RAM <span>6144MB</span></>),
            disk : (<>DISK <span>80GB</span></>),
            maxDomains : (<><Text id="plans-max-domains">MAX domains</Text> <span>100</span></>),
            maxSshAccounts : (<><Text id="plans-max-ssh-accounts">MAX SSH accounts</Text> <span>100</span></>),
        }
    ]
    const options = [
        {
            id : "m",
            text : (<Text id="plans-monthly">Monthly</Text>)
        },
        {
            id : "h",
            text : (<Text id="plans-hourly">Hourly</Text>)
        }
    ]
    const onBillingToggle = optionId => {
        setBillingPeriod(optionId)
    }
    const { zeroDelimiter } = useText({
        zeroDelimiter : <Text id="zero-delimiter">.</Text>
    });
    return (
        <div className={style.serverletPlans}>
            <TextToggle options={options} onChange={onBillingToggle} marginTop={true}/>
            <PlansSlideshow>
                {plans.map(plan => {
                    let labelClass = '';
                    labelClass = plan.label === "popular" ? plansStyle.popular : labelClass;

                    return (
                        <div className={`${plansStyle.plan} ${labelClass}`}>
                            {
                                plan.label &&
                                <div className={plansStyle.label}>{plan.labelTitle}</div>
                            }
                            <div className={plansStyle.section}>
                                <span className={plansStyle.title}>{plan.title}</span>
                                {
                                    billingPeriod === 'm' &&
                                    <span className={plansStyle.subtitle}>{plan.monthlyPrice} {plan.currency} <i><Text id="monthly">monthly</Text></i></span>
                                }
                                {
                                    billingPeriod === 'h' &&
                                    <span className={plansStyle.subtitle}>{plan.hourlyPrice.toString().replace(".",zeroDelimiter)} {plan.currency} <i><Text id="hourly">hourly</Text></i></span>
                                }
                                <span className={plansStyle.description}>{plan.description}</span>
                            </div>
                            <div className={plansStyle.section}>
                                <div className={plansStyle.line}>{plan.cpu}</div>
                                <div className={plansStyle.line}>{plan.ram}</div>
                                <div className={plansStyle.line}>{plan.disk}</div>
                                <div className={plansStyle.line}>{plan.maxDomains}</div>
                                <div className={plansStyle.line}>{plan.maxSshAccounts}</div>
                            </div>
                        </div>
                    )
                })}
            </PlansSlideshow>
        </div>
    )
};

export default ServerletPlans;
