import { Link } from 'preact-router/match';
import style from './style.css';
import LoginPanel from "../LoginPanel";
import {useEffect, useRef, useState} from "preact/hooks";
import {cloneElement, createRef} from "preact";
import {Text} from "preact-i18n";
import {getCurrentUrl, route} from "preact-router";


const HorizontalMenu = (props) => {
    const [ activeLink, setActiveLink ] = useState(props.children[0].props.href);

    const onScroll = () => {
        const children = [...props.children].reverse();
        let done = false;
        children.forEach(child => {
            if(child.props.href.includes("#") && !done){
                const anchor = child.props.href.split("#")[1] ?? '';;
                const anchorOffset = document.getElementById(anchor).offsetTop;
                if(window.scrollY >= anchorOffset){
                    setActiveLink(child.props.href);
                    done = true;
                }
            }
        })
    };

    useEffect(() => {
        document.addEventListener('scroll', onScroll);
        return () => {
            document.removeEventListener('scroll', onScroll)
        }
    }, []);

    return (
        <div className={style.horizontalMenu}>
            <div className={style.inner}>
                {
                    props.children.map(child => {
                        const props = {
                            ...child.props,
                            className: `${child.props.className ?? ''} ${child.props.href === activeLink && style.active}`,
                            activeClassName : style.active
                        }
                        return cloneElement(child, props)
                    })
                }
            </div>
        </div>
    )
};

export default HorizontalMenu;
