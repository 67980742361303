import { Link } from 'preact-router/match';
import PlansSlideshow from "../PlansSlideshow";
import style from '../PlansSlideshow/style.css'
import {Text} from "preact-i18n";

const OtherServices = (props) => {
    return (
        <PlansSlideshow flex={true}>
            <div className={`${style.plan}`}>
                <div className={style.section}>
                    <span className={style.subtitle}>250 <Text id="czk">CZK</Text></span>
                    <span className={style.description}><Text id="other-plan-cms-installation">Installation of CMS performed by our technical support.</Text></span>
                </div>
            </div>
            <div className={`${style.plan}`}>
                <div className={style.section}>
                    <span className={style.subtitle}>500 <Text id="czk">CZK</Text></span>
                    <span className={style.description}><Text id="other-plan-cms-repair">Repair of corrupted CMS performed by our technical support.</Text></span>
                </div>
            </div>
            <div className={`${style.plan}`}>
                <div className={style.section}>
                    <span className={style.subtitle}>500 <Text id="czk">CZK</Text></span>
                    <span className={style.description}><Text id="other-plan-web-server-configuration">Invidual configutation of web server performed by our technical support.</Text></span>
                </div>
            </div>
            <div className={`${style.plan}`}>
                <div className={style.section}>
                    <span className={style.subtitle}>900 <Text id="czk">CZK</Text></span>
                    <span className={style.description}><Text id="other-plan-web-migration-to-us">Web site migration from other web hoster performed by our technical support.</Text></span>
                </div>
            </div>
            <div className={`${style.plan}`}>
                <div className={style.section}>
                    <span className={style.subtitle}>1800 <Text id="czk">CZK</Text></span>
                    <span className={style.description}><Text id="other-plan-web-migration-from-us">Web site migration from us to other web hoster performed by our technical support.</Text></span>
                </div>
            </div>
        </PlansSlideshow>
    )
};

export default OtherServices;
