import { h } from 'preact';
import Banner from "../../Banner";
import Content from "../../Content";
import BannerCompare from "../../BannerCompare";
import Boxes from "../../Boxes";
import Box from "../../Box";
import {Fragment} from "preact";
import Footer from "../../Footer";

const Home = () => {
	console.log("home render");
	return (
		<Fragment>
			<Content>
				<Banner/>
				<BannerCompare/>
			</Content>
			<Content shifted={true} withShadow={true}>
				<Boxes>
					<Box>
						<h2>Zřízení do několika málo minut</h2>
						<p>Náš systém vás v práci brzdit nebude.</p>
						<a href="src/components/Pages/home/index#">Zjistit více</a>
					</Box>
					<Box>
						<h2>Jeden webhosting až pro 100 domén</h2>
						<p>Mezi doménami a subdoménami neděláme rozdíly.</p>
					</Box>
					<Box>
						<h2>Přehledné a jednoduché administrační rozhraní,</h2>
						<p>kde najdete vše potřebné</p>
					</Box>
				</Boxes>
			</Content>
			<Footer/>
		</Fragment>
	)
};

export default Home;
