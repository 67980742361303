import { Link } from 'preact-router/match';
import style from './style.css';
import {Text} from "preact-i18n";

const Error404 = () => (
    <div>
        <h1><Text id="error404-h1">Page was not found</Text></h1>
    </div>
);

export default Error404;
