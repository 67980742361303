module.exports = {
    "langs-dir" : "./l10n/",
    "langs-default" : "en",
    "langs" : [
      "en", "cs"
    ],
    "routes" : [
        { url: "/" },
        { url: "/products" },
        { url: "/pricing" },
        { url: "/about-us" }
    ]
}