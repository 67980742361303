import { Link } from 'preact-router/match';
import style from './style.css';
import {Text, translate} from "preact-i18n";
import {useEffect, useState} from "preact/hooks";

const Banner = () => {
    const timePerSlide = 10000;
    const slides = [
        {
            title : "Discover hosting of a new generation",
            subtitle : "With blazing performance for your applications",
            img : "/assets/img/pipelines.svg",
            imgAlt : ""
        },
        {
            title : "Pick your favourite programming language",
            subtitle : "Our serverlets will not limit you"
        }
    ];
    let slideChanger;
    const [ slideNum, setSlideNum] = useState(0);

    const onPrevClick = () => {
        setSlideNum(slideNum > 0 ? slideNum - 1 : slides.length -1)
        clearInterval(slideChanger)
    }
    const onNextClick = () => {
        setSlideNum(slideNum < slides.length -1 ? slideNum + 1 : 0)
        clearInterval(slideChanger)
    }

    useEffect(() => {
        slideChanger = setInterval(onNextClick, timePerSlide);
        return () => {
            clearInterval(slideChanger)
        }
    }, [slideNum])

    const slidesDivs = slides.map((slide, i) => {
        return (
            <div
                className={style.slide}
                style={{
                    transform: `translate(-${slideNum*100}%,0)`,
                }}
            >
                <div className={style.imgContainer}>
                    {
                        slides[i].img &&
                        <>
                            <div className={style.smallCircle}/>
                            <div className={style.bigCircle}/>
                            <img src={slides[i].img} alt={slides[i].imgAlt ?? ''}/>
                        </>
                    }
                </div>
                <h1><Text id={`banner-slide${i}-title`}>{slides[i].title}</Text></h1>
                <h2><Text id={`banner-slide${i}-subtitle`}>{slides[i].subtitle}</Text></h2>
            </div>
        )
    })

    return (
        <div className={style.banner}>
            <div className={style.controls}>
                <button onClick={onPrevClick} className={`${style.prev} ${slideNum === 0 && style.inactive}`}></button>
                <button onClick={onNextClick} className={`${style.next} ${slideNum === slides.length -1 && style.inactive}`}></button>
            </div>
            <div className={style.slides}>
                {slidesDivs}
            </div>
        </div>
    )
};

export default Banner;
