import { Link } from 'preact-router/match';
import style from './style.css';
import {useState} from "preact/hooks";

const TextToggle = (props) => {
    const [ active, setActive ] = useState(0)
    const onClick = (e) => {
        setActive(props.options[0].id === e.target.name ? 0 : 1)
        if(props.onChange){
            props.onChange(e.target.name)
        }
    }
    return (
        <span className={`${style.textToggle} ${props.marginTop && style.textToggleMarginTop}`}>
            <input id="toggle-on" onClick={onClick} className={`${style.toggle} ${style.toggleLeft}`} name={props.options[0].id}  type="radio" value={(active === 0).toString()} checked={active === 0}/>
            <label htmlFor="toggle-on" className={style.button}>{props.options[0].text}</label>
            <input id="toggle-off" onClick={onClick} className={`${style.toggle} ${style.toggleRight}`} name={props.options[1].id} type="radio" value={(active === 1).toString()} checked={active === 1}/>
            <label htmlFor="toggle-off" className={style.button}>{props.options[1].text}</label>
        </span>
    )
};

export default TextToggle;
