import { Link } from 'preact-router/match';
import style from './style.css';

const Page = (props) => {
    return (
        <div className={`${style.page} ${props.withLine && style.withLine} ${props.withShadow && style.withShadow} ${props.className ?? ''}`}>
            {props.children}
        </div>
    )
}

export default Page;
