import { Link } from 'preact-router/match';
import style from './style.css';
import {Text} from "preact-i18n";

const BannerCompare = () => (
    <div className={style.bannerCompare}>
        <div className={style.part}>
            <h2><Text id="compare-hourly">Hourly</Text></h2>
            <p><Text id="compare-billing">billing</Text></p>
        </div>
        <div className={style.part}>
            <h2><Text id="compare-scalable">Scalable</Text></h2>
            <p><Text id="compare-plans">plans</Text></p>
        </div>
        <div className={style.part}>
            <h2><Text id="compare-technologies">Technologies</Text></h2>
            <p>PHP8, Redis</p>
        </div>
        <div className={style.part}>
            <Link href="" className={style.button}><Text id="compare-begin">Begin</Text></Link>
        </div>
    </div>
);

export default BannerCompare;
