import './index.css'
import { Router } from 'preact-router';
import { JSX } from 'preact';
import { hydrate, render } from "preact";

import Header from './components/Header'
import Home from './components/Pages/home';
import Footer from "./components/Footer";
import LocaleProvider from "./components/LocaleProvider";
import Error404 from "./components/404";
import Products from "./components/Pages/products";
import Pricing from "./components/Pages/pricing";
import AboutUs from "./components/Pages/about-us";
import {Fragment} from "preact";
import RouterContainer from "./components/RouterContainer";

const App = () => (
    <div id="app">
        <Router>
            <LocaleProvider path="/:lang?/:anything?*" render={props => {
                return (
                    <Fragment>
                        <Header lang={props.lang} langUrl={props.langUrl}/>
                        <RouterContainer>
                            <Router>
                                <Home path={`${props.langUrl}/`} lang={props.lang} langUrl={props.langUrl} />
                                <Products path={`${props.langUrl}/products`} lang={props.lang} langUrl={props.langUrl} />
                                <Pricing path={`${props.langUrl}/pricing`} lang={props.lang} langUrl={props.langUrl} />
                                <AboutUs path={`${props.langUrl}/about-us`} lang={props.lang} langUrl={props.langUrl} />
                                <Error404 path="/*" lang={props.lang} langUrl={props.langUrl} />
                            </Router>
                        </RouterContainer>
                    </Fragment>
                )
            }}/>
        </Router>
    </div>
)

export default App;