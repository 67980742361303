import {Fragment} from "preact";
import Footer from "../../Footer";
import Page from "../../Page";

const Products = () => (
    <Fragment>
        <Page>

        </Page>
        <Footer/>
    </Fragment>
);

export default Products;
