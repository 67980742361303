import {Fragment} from "preact";
import Footer from "../../Footer";
import Page from "../../Page";
import style from "./style.css";
import HorizontalMenu from "../../HorizontalMenu";
import {Text, useText} from "preact-i18n";
import ServerletPlans from "../../ServerletPlans";
import Content from "../../Content";
import {useEffect, useState} from "preact/hooks";
import SocialMedia from "../../SocialMedia";

const AboutUs = () =>{
    const [ showEmail, setShowEmail ] = useState(false);
    const obfuscateMail = (mail) => {
        let characters = 'FGcdefHIQxyzRSTUVlmnov45WXYZabghiJKLABCDpqrstuEMNOPjkw';
        return mail.split('').map((symbol, i) => {
            return (
                <>
                    <i className={style.mailS}>{symbol}</i>
                    <i className={style.mailN}>{characters[i % characters.length]}</i>
                </>
            )
        })
    }
    const { email } = useText({
        email : <Text id="contact-us-email">hello@futrou.com</Text>
    });
    const honzaEmail = "havlena@futrou.com";
    const danekEmail = "siazik@futrou.com";

    useEffect(() => {
        if(window !== undefined){
            setShowEmail(true);
        }
    }, []);

    return (
        <Fragment>
            <Page withLine={false} withShadow={false} className={`${style.aboutUs} ${style.insetShadow}`}>
                <HorizontalMenu>
                    <a href="#our-vision"><Text id="our-vision-title">Our vision</Text></a>
                    <a href="#our-team"><Text id="our-team">Our team</Text></a>
                    <a href="#contact-us"><Text id="contact-us">Contact us</Text></a>
                </HorizontalMenu>
                <Content>
                    <div id="our-vision" className={style.anchor}/>
                    <div className={`${style.section} ${style.widthDots}`}>
                        <div className={style.icon}>
                            <img src="/assets/img/icons/heart.svg"/>
                        </div>
                        <h1><Text id="our-vision-title">Our vision</Text></h1>
                        <div className={style.post}>
                            <p>
                                <Text id="our-vision-text1">
                                    We would like to change an established notion that the website hosting = PHP, Apache a MySQL.
                                </Text>
                            </p>
                            <p>
                                <Text id="our-vision-text2">
                                    Time moves on. New technologies are created every day.
                                    Everyone should have an option to create website in his/her favourite language and the Futrou will not put obstacles in your way.
                                </Text>
                            </p>
                        </div>
                    </div>

                    <div id="our-team" className={style.anchor}/>
                    <div className={style.section}>
                        <h1><Text id="our-team-title">Our team</Text></h1>
                        <p><Text id="our-team-subtitle">which is caring for your websites</Text></p>
                        <div className={style.members}>
                            <div className={style.member}>
                                <div className={style.imgContainer}>
                                    <img src="/assets/img/members/av1.png"/>
                                </div>
                                <h1>Honza</h1>
                                <span className={style.email}>{showEmail ? obfuscateMail(honzaEmail) : "..."}</span>
                            </div>
                            <div className={style.member}>
                                <div className={style.imgContainer}>
                                    <img src="/assets/img/members/av2.png"/>
                                </div>
                                <h1>Danek</h1>
                                <span className={style.email}>{showEmail ? obfuscateMail(danekEmail) : "..."}</span>
                            </div>
                        </div>
                    </div>

                    <div id="contact-us" className={style.anchor}/>
                    <div className={style.section}>
                        <div className={style.icon}>
                            <img src="/assets/img/icons/contact.svg"/>
                        </div>
                        <h1><Text id="contact-us-title">Contact us</Text></h1>
                        <p><Text id="contact-us-subtitle">Feel free to contact us with any question.</Text></p>
                        <p><Text id="contact-us-subsubtitle">We love to chat.</Text></p>
                        <div className={style.bigEmail}>{showEmail ? obfuscateMail(email) : "..."}</div>
                    </div>
                </Content>
            </Page>
            <Footer/>
        </Fragment>
    )
};

export default AboutUs;
