import { Link } from 'preact-router/match';
import style from './style.css';

const Content = (props) => (
    <div className={`${style.content} ${props.noMinHeight && style.noMinHeight} ${props.shifted ? style.shifted : ""}  ${props.withShadow && style.withShadow}`}>
        {props.children}
    </div>
);

export default Content;
