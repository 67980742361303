import { Link } from 'preact-router/match';
import style from './style.css';
import {useState} from "preact/hooks";

const PlansSlideshow = (props) => {
    const flex = props.flex ?? false;
    const planSize = "20";
    const [ itemsCount, setItemsCount ] = useState(props.children.length);
    const [ position, setPosition ] = useState(0);
    const [ showMore, setShowMore ] = useState(false);

    const onPrevClick = () => {
        setPosition(position > 0 ? position - 1 : position);
    }
    const onNextClick = () => {
        setPosition(position < itemsCount-1 ? position + 1 : position);
    }

    const onShowMoreClick = () => {

    }

    return (
        <div className={style.plans}>
            <div className={style.controls}>
                <button onClick={onPrevClick} className={`${style.prev} ${(position === 0 || flex) && style.inactive}`}></button>
                <button onClick={onNextClick} className={`${style.next} ${(position === itemsCount-1 || flex) && style.inactive}`}></button>
            </div>
            <div className={`${style.inner} ${flex && style.innerFlex}`} style={{ transform : `translate(-${position*planSize}em,0)` }}>
                {props.children}
            </div>
        </div>
    )
};

export default PlansSlideshow;
