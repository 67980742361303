import {Fragment} from "preact";
import style from "./style.css"
import {Text} from "preact-i18n";

const SocialMedia = () => (
    <div className={style.socialMedia}>
        <div className={style.medium}>

        </div>
        <div className={style.medium}>

        </div>
    </div>
);

export default SocialMedia;
